import React, { useEffect, useState } from "react";
import type { MenuProps } from "antd";
import { Layout, Menu } from "antd";
import { useNavigate } from "react-router-dom";
import {
  Clock,
  Document,
  Driver,
  Grid5,
  Money2,
  Profile2User,
  Setting2,
  UserSearch,
  UserTick,
} from "iconsax-react";

const { Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem("Master Setup", "/app/master", <Setting2 />),
  getItem("Projects", "/app/projects", <Grid5 />),
  getItem("Employee Master Data", "/app/employees", <Profile2User />),
  getItem("Employee Transfer", "/app/employee-transfers", <UserTick />),
  getItem("Recruitment", "/app/job-sequences", <UserSearch />),
  getItem("Documents", "/app/drive", <Driver />),
  getItem("Field Repository", "/app/field-repos/employees", <Document />),
  getItem("Time Tracking", "/app/time-entries", <Clock />),
  getItem("Payroll", "/app/payrolls", <Money2 />),
  //   getItem("User", "sub1", <UserOutlined />, [
  //     getItem("Tom", "3"),
  //     getItem("Bill", "4"),
  //     getItem("Alex", "5"),
  //   ]),
];

const Sidebar: React.FC = () => {
  const [collapsed, setCollapsed] = useState(true);
  const navigate = useNavigate();
  const [top, setTop] = useState<number>(63);

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    navigate(e.key);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const newTop = Math.max(63 - scrollTop, 0);
      setTop(newTop);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const contentContainer = document.querySelector(
      ".content-container"
    ) as HTMLElement;
    if (contentContainer) {
      contentContainer.style.marginLeft = collapsed ? "64px" : "300px";
    }
  }, [collapsed]);

  return (
    <div className="side-navigationbar" style={{ top: `${top}px` }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        className={collapsed ? "collapsed-menu" : "large-menu"}
      >
        <Menu
          theme="light"
          defaultSelectedKeys={["1"]}
          mode="inline"
          items={items}
          onClick={handleMenuClick}
        />
      </Sider>
    </div>
  );
};

export default Sidebar;
