import { Col, Row, Space, Tooltip, Radio, message } from "antd";
import _ from "lodash";
import React, { ReactNode, useState } from "react";
import Button from "../Components/Button";
import ModalBox from "../Components/ModalBox/ModalBox";
import { validateAccess } from "../util/util";
import apiClient from "../util/apiClient";
import apiRequest from "../util/apiClient";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";

type TTableLayout = {
  title: string | ReactNode;
  children: ReactNode;
  rightSection?: ReactNode;
  addButton?: {
    title: string;
    onClick: () => void;
    access: string;
  };
  exportUrl?: string;
  detailed?: boolean;
  filterData?: Record<string, unknown>;
};

export default function TableLayout({
  title,
  exportUrl = "",
  rightSection,
  addButton,
  filterData: params,
  detailed,
  children,
}: TTableLayout) {
  const [exportType, setExportType] = useState("simple");
  const [visible, setVisible] = useState(false);
  const accessData =
    useSelector((state: RootState) => state.userState.access) || [];
  const exportExcel = async () => {
    try {
      const response = await apiRequest(exportUrl, "GET", {
        params: _.omit(
          {
            ...params,
            ...(detailed && { exportType }),
          },
          ["page", "perPage", "totalCount"]
        ),
        responseType: "blob",
      });

      const blob = new Blob([response], { type: "application/vnd.ms-excel" });
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = "export.xlsx"; // Default filename, you can adjust based on response
      document.body.appendChild(a);
      a.click();
      a.remove();
      setVisible(false);
    } catch (error) {
      message.error("Failed to export Excel. Please try again.");
    }
  };

  return (
    <div className="table-layout px-3">
      <ModalBox
        title="Export Excel"
        width={400}
        visible={visible}
        onOk={exportExcel}
        okText="Export"
        onCancel={() => setVisible(false)}
        destroyOnClose
      >
        <Radio.Group
          onChange={(e) => setExportType(e.target.value)}
          value={exportType}
        >
          <Radio value="simple">Simple</Radio>
          <Radio value="detailed">Detailed</Radio>
        </Radio.Group>
      </ModalBox>
      <Row justify="space-between" align="middle">
        <Col>
          {title &&
            (typeof title === "string" ? (
              <h2 className="table-title">{title}</h2>
            ) : (
              title
            ))}
        </Col>
        <Col className="pt-1">
          <Space>
            {rightSection}
            {exportUrl && (
              <Tooltip title="Export Excel" placement="left">
                <Button
                  // variant="text"
                  className="d-inline-flex align-items-center p-0"
                  onClick={() => (detailed ? setVisible(true) : exportExcel())}
                >
                  <i
                    style={{ fontSize: "25px" }}
                    className="text-success flaticon-csv-file-format-extension no-margin d-inline-flex"
                  />
                </Button>
              </Tooltip>
            )}
          </Space>
        </Col>
      </Row>
      {addButton && (
        <div className="add-button">
          {typeof addButton === "function"
            ? React.createElement(addButton)
            : validateAccess(accessData, addButton.access) && (
                <Button
                  onClick={addButton.onClick}
                  appearance="primary"
                  className="btn-block"
                >
                  <i className="flaticon-plus" /> {addButton.title}
                </Button>
              )}
        </div>
      )}
      {children}
    </div>
  );
}
