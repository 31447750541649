import { Helmet } from 'react-helmet';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import Footer from '../Screens/Footer/Footer';
import Header from '../Screens/Header/Header';

function RouteWrapper(props:any) {
    const { screen: Comp, metaObj, headerFooter } = props;
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // Scroll to the top when the location (route) changes
        const appView = document.getElementById('app-view');
        if (appView) {
            appView.scrollIntoView();
        }
    }, [location]);

    const defaultMetaObject = {
        title: metaObj?.title || 'Accqrate Human Capital management solution',
        description: metaObj?.description || 'Accqrate Human Capital management solution',
        keyword: metaObj?.keyword || 'Accqrate Human Capital management solution',
    };

    return (
        <>
            <span id="app-view" />
            <Helmet>
                <title>{defaultMetaObject.title}</title>
                <meta name="description" content={defaultMetaObject.description} />
                <meta name="keyword" content={defaultMetaObject.keyword} />
            </Helmet>
            {headerFooter && <Header />}
            <Comp {...props} />
            {headerFooter && <Footer />}
        </>
    );
}

export default RouteWrapper;
