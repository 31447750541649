import React, {useEffect, useRef} from 'react';
import logo from './logo.svg';
import './App.css';
import AppRoutes from "./routes";
import {BrowserRouter} from "react-router-dom";
import TopLoaderBox from "./Components/LoaderBox/TopLoaderBox";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

export const loaderRef = { barRef: null }

function App() {
    const myRef = useRef(null)

    useEffect(() => {
        // @ts-ignore
        loaderRef.barRef = myRef
    }, [])
  // @ts-ignore
    return (
      <>
        <DndProvider backend={HTML5Backend}>
          <BrowserRouter>

            <AppRoutes></AppRoutes>
          </BrowserRouter>
        </DndProvider>
      </>
  );
}

export default App;
