import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouterProvider, createBrowserRouter, RouteObject } from 'react-router-dom';
import { BrowserRouter , Routes, Route, Navigate } from 'react-router-dom';


import {setLanguage} from "../store/slice/languageSlice";
import {AppDispatch, RootState} from "../store/store";
import InnerRoutes from "./innnerRoutes";
import {NotFound} from "../Screens/notFound";
import Login from "../Screens/Login/Login";
import PublicRoutes from "./publicRoutesConfig";


const AppRoutes = () => {
    const [init, setInit] = useState(false);
    const dispatch = useDispatch<AppDispatch>();

    // Use selectors to access state
    const userInfoState = useSelector((state: RootState) => state.userState);
    const { userInfo } = userInfoState;
    console.log(userInfo)
    useEffect(()=>{
        console.log(userInfo)
    },[userInfo])
    useEffect(() => {
        setInit(true);
        // Example of dispatching an action to set language
        dispatch(setLanguage('en'));
    }, [dispatch]);

    const routes: RouteObject[] = Object.keys(userInfo).length !== 0 ? [
        {
            path: "/app",
            element: <InnerRoutes />,
        }
    ] : [
        {
            path: "/*",
            element: <PublicRoutes />,
        },
    ];

    const router = createBrowserRouter(routes);

    if (!init) {
        return null; // Show a loader or nothing while initializing
    }

    return (
        <Routes>
            {( Object.keys(userInfo).length !== 0) && (
                <Route path="/app/*" element={<InnerRoutes />} />
            )}
            {/* Public routes for all other paths */}
            <Route path="*" element={<PublicRoutes />} />
        </Routes>
    );
};

export default AppRoutes;
