// import { useEffect, useState } from "react";
// import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
// // import { getTimeEntryById, getUserByToken } from '../Actions/UserAction'
// // import LoaderBox from '../Components/LoaderBox/LoaderBox'
// // import Layout from '../Layout/Layout'

// import { useDispatch, useSelector } from "react-redux";
// import { FullLoader, SideBarLoader } from "../Components/LoaderBox/Loader";
// import LoaderBox from "../Components/LoaderBox/LoaderBox";
// import Layout from "../Layout/Layout";
// import { NotFound } from "../Screens/notFound";
// import { setTimerCount } from "../store/slice/userInfoSlice";
// import { AppDispatch, RootState } from "../store/store";
// import AsyncRoute from "./asyncRoutes";
// import RouteWrapper from "./RouteWrapper";
// import { Modal } from "antd";
// import ReleaseInfo from "./ReleaseInfo";

// const getRoutes = (url: string) => [
//   {
//     path: `/dashboard`,
//     component: FullLoader(() => import("../Screens/Dashboard/dashboard")),
//   },
//   {
//     path: `/app`,
//     component: FullLoader(() => import("../Screens/Dashboard/dashboard")),
//   },
//   {
//     path: `/manage-company`,
//     component: FullLoader(() => import('../Screens/MasterData/Companies/SetCompany'))
//   }
// ];
import { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FullLoader, SideBarLoader } from "../Components/LoaderBox/Loader";
import LoaderBox from "../Components/LoaderBox/LoaderBox";
import Layout from "../Layout/Layout";
import { NotFound } from "../Screens/notFound";
import { setTimerCount } from "../store/slice/userInfoSlice";
import { AppDispatch, RootState } from "../store/store";
import AsyncRoute from "./asyncRoutes";
import RouteWrapper from "./RouteWrapper";
import { Modal } from "antd";
import ReleaseInfo from "./ReleaseInfo";

// Define interface for route types
interface BasicRoute {
  path: string;
  component: React.ComponentType<any>;
}

interface AsyncRoute {
  path: string;
  screen: React.ComponentType<any>;
  access: string | string[];
  [key: string]: any;
}

// Define routes with proper typing
const getRoutes = (url: string): BasicRoute[] => [
  {
    path: `/dashboard`,
    component: FullLoader(() => import("../Screens/Dashboard/dashboard")),
  },
  {
    path: `/app`,
    component: FullLoader(() => import("../Screens/Dashboard/dashboard")),
  },
  {
    path: `/manage-company`,
    component: FullLoader(() => import('../Screens/MasterData/Companies/SetCompany')),
  }
];

// Define the async routes
const getAsyncRoutes = (url: string) => [
  {
    path: `/master`,
    screen: FullLoader(() => import("../Screens/MasterData/Main")),
    access: "",
  },
  {
    path: `/add-nationality`,
    screen: FullLoader(
      () => import("../Screens/MasterData/Nationality/NationalityForm")
    ),
    access: "",
  },
  {
    path: `/nationalities`,
    screen: FullLoader(
      () => import("../Screens/MasterData/Nationality/Nationalities")
    ),
    access: "",
  },
  {
    path: `/nationality/:id`,
    screen: FullLoader(
      () => import("../Screens/MasterData/Nationality/NationalityForm")
    ),
    access: "",
  },
  {
    path: `/job-levels`,
    screen: FullLoader(
      () => import("../Screens/MasterData/JobLevels/JobLevels")
    ),
  },
  {
    path: `/add-job-level`,
    screen: FullLoader(
      () => import("../Screens/MasterData/JobLevels/JobLevelForm")
    ),
  },
  {
    path: `/job-level/:id`,
    screen: FullLoader(
      () => import("../Screens/MasterData/JobLevels/JobLevelForm")
    ),
  },
  {
    path: `/add-job-band`,
    screen: FullLoader(
      () => import("../Screens/MasterData/JobBands/JobBandForm")
    ),
  },

  {
    path: `/job-bands`,
    screen: FullLoader(() => import("../Screens/MasterData/JobBands/JobBands")),
  },

  {
    path: `/job-band/:id`,
    screen: FullLoader(
      () => import("../Screens/MasterData/JobBands/JobBandForm")
    ),
  },
  {
    path: `/roles`,
    screen: FullLoader(() => import("../Screens/MasterData/Roles/Roles")),
  },
  {
    path: `/rolesnew`,
    screen: FullLoader(() => import("../Screens/MasterData/Roles/RolesNew")),
  },
  {
    path: `/add-role`,
    screen: FullLoader(() => import("../Screens/MasterData/Roles/RoleFormNew")),
  },
  {
    path: `/users`,
    screen: SideBarLoader(() => import("../Screens/MasterData/Users/UsersNew")),
  },
  {
    path: `/location`,
    screen: FullLoader(() => import("../Screens/MasterData/Location/Location")),
  },
  {
    path: `/add-employee-group`,
    screen: FullLoader(
      () => import("../Screens/MasterData/EmployeeGroups/EmployeeGroupForm")
    ),
  },
  {
    path: `/employee-groups`,
    screen: FullLoader(
      () => import("../Screens/MasterData/EmployeeGroups/EmployeeGroups")
    ),
  },
  {
    path: `/employee-groups/:id`,
    screen: FullLoader(
      () => import("../Screens/MasterData/EmployeeGroups/EmployeeGroupForm")
    ),
  },
  {
    path: `/options`,
    screen: SideBarLoader(
      () => import("../Screens/MasterData/Options/Options")
    ),
  },
  {
    path: `/add-option`,
    screen: FullLoader(
      () => import("../Screens/MasterData/Options/OptionForm")
    ),
  },
  {
    path: `/edit-option/:id`,
    screen: FullLoader(
      () => import("../Screens/MasterData/Options/OptionForm")
    ),
  },
  {
    path: `/companies`,
    screen: FullLoader(
      () => import("../Screens/MasterData/Companies/Companies")
    ),
  },
  {
    path: `/edit-company/:id`,
    screen: FullLoader(
      () => import("../Screens/MasterData/Companies/CompanyForm")
    ),
  },
  {
    path: `/add-employee-sub-group`,
    screen: FullLoader(
      () =>
        import("../Screens/MasterData/EmployeeSubGroups/EmployeeSubGroupForm")
    ),
  },

  {
    path: `/employee-sub-groups`,
    screen: FullLoader(
      () => import("../Screens/MasterData/EmployeeSubGroups/EmployeeSubGroups")
    ),
  },

  {
    path: `/employee-sub-group/:id`,
    screen: FullLoader(
      () =>
        import("../Screens/MasterData/EmployeeSubGroups/EmployeeSubGroupForm")
    ),
  },
  {
    path: `/add-gender`,
    screen: FullLoader(
      () => import("../Screens/MasterData/Genders/GenderForm")
    ),
  },
  {
    path: `/genders`,
    screen: FullLoader(() => import("../Screens/MasterData/Genders/Genders")),
  },
  {
    path: `/genders/:id`,
    screen: FullLoader(
      () => import("../Screens/MasterData/Genders/GenderForm")
    ),
  },
  {
    path: `/add-wage-type`,
    screen: FullLoader(
      () => import("../Screens/MasterData/WageTypes/WageTypeForm")
    ),
  },
  {
    path: `/wage-type/:id`,
    screen: FullLoader(
      () => import("../Screens/MasterData/WageTypes/WageTypeForm")
    ),
  },
  {
    path: `/wage-types`,
    screen: FullLoader(
      () => import("../Screens/MasterData/WageTypes/WageTypes")
    ),
  },
  {
    path: `/add-organization-unit`,
    screen: FullLoader(
      () =>
        import("../Screens/MasterData/OrganizationUnits/OrganizationUnitForm")
    ),
  },
  {
    path: `/organization-unit/:id`,
    screen: FullLoader(
      () =>
        import("../Screens/MasterData/OrganizationUnits/OrganizationUnitForm")
    ),
  },
  {
    path: `/organization-units`,
    screen: FullLoader(
      () => import("../Screens/MasterData/OrganizationUnits/OrganizationUnits")
    ),
  },
  {
    path: `/add-position`,
    screen: FullLoader(
      () => import("../Screens/MasterData/Positions/PositionForm")
    ),
  },
  {
    path: `/position/:id`,
    screen: FullLoader(
      () => import("../Screens/MasterData/Positions/PositionForm")
    ),
  },
  {
    path: `/positions`,
    screen: FullLoader(
      () => import("../Screens/MasterData/Positions/Positions")
    ),
  },
  {
    path: `/add-operational-level-1`,
    screen: FullLoader(
      () =>
        import("../Screens/MasterData/OperationalLevel1/OperationalLevel1Form")
    ),
  },
  {
    path: `/operational-level-1/:id`,
    screen: FullLoader(
      () =>
        import("../Screens/MasterData/OperationalLevel1/OperationalLevel1Form")
    ),
  },
  {
    path: `/operational-level-1`,
    screen: FullLoader(
      () => import("../Screens/MasterData/OperationalLevel1/OperationalLevel1")
    ),
  },
  {
    path: `/add-operational-level-2`,
    screen: FullLoader(
      () =>
        import("../Screens/MasterData/OperationalLevel2/OperationalLevel2Form")
    ),
  },
  {
    path: `/operational-level-2/:id`,
    screen: FullLoader(
      () =>
        import("../Screens/MasterData/OperationalLevel2/OperationalLevel2Form")
    ),
  },
  {
    path: `/operational-level-2`,
    screen: FullLoader(
      () => import("../Screens/MasterData/OperationalLevel2/OperationalLevel2")
    ),
  },
  {
    path: `/add-operational-level-3`,
    screen: FullLoader(
      () =>
        import("../Screens/MasterData/OperationalLevel3/OperationalLevel3Form")
    ),
  },
  {
    path: `/operational-level-3`,
    screen: FullLoader(
      () => import("../Screens/MasterData/OperationalLevel3/OperationalLevel3")
    ),
  },
  {
    path: `/operational-level-3/:id`,
    screen: FullLoader(
      () =>
        import("../Screens/MasterData/OperationalLevel3/OperationalLevel3Form")
    ),
  },
  {
    path: `/add-disability`,
    screen: FullLoader(
      () => import("../Screens/MasterData/Disabilities/DisabilityForm")
    ),
  },
  {
    path: `/disability/:id`,
    screen: FullLoader(
      () => import("../Screens/MasterData/Disabilities/DisabilityForm")
    ),
  },
  {
    path: `/disabilities`,
    screen: FullLoader(
      () => import("../Screens/MasterData/Disabilities/Disablities")
    ),
  },
  {
    path: `/add-military`,
    screen: FullLoader(
      () => import("../Screens/MasterData/MilitaryStatus/MilitaryStatusForm")
    ),
  },
  {
    path: `/military-status/:id`,
    screen: FullLoader(
      () => import("../Screens/MasterData/MilitaryStatus/MilitaryStatusForm")
    ),
  },
  {
    path: `/military-status`,
    screen: FullLoader(
      () => import("../Screens/MasterData/MilitaryStatus/MilitaryStatus")
    ),
  },
  {
    path: `/add-title`,
    screen: FullLoader(() => import("../Screens/MasterData/Titles/TitlesForm")),
  },
  {
    path: `/title/:id`,
    screen: FullLoader(() => import("../Screens/MasterData/Titles/TitlesForm")),
  },
  {
    path: `/titles`,
    screen: FullLoader(() => import("../Screens/MasterData/Titles/Titles")),
  },
  {
    path: `/add-citizenship`,
    screen: FullLoader(
      () => import("../Screens/MasterData/Citizenship/CitizenshipForm")
    ),
  },
  {
    path: `/citizenship`,
    screen: FullLoader(
      () => import("../Screens/MasterData/Citizenship/Citizenship")
    ),
  },
  {
    path: `/citizenship/:id`,
    screen: FullLoader(
      () => import("../Screens/MasterData/Citizenship/CitizenshipForm")
    ),
  },
  {
    path: `/add-religion`,
    screen: FullLoader(
      () => import("../Screens/MasterData/Religions/ReligionForm")
    ),
  },
  {
    path: `/religions`,
    screen: FullLoader(
      () => import("../Screens/MasterData/Religions/Religions")
    ),
  },
  {
    path: `/religion/:id`,
    screen: FullLoader(
      () => import("../Screens/MasterData/Religions/ReligionForm")
    ),
  },
  {
    path: `/add-paygroup`,
    screen: FullLoader(
      () => import("../Screens/MasterData/PayGroups/PayGroupForm")
    ),
  },
  {
    path: `/paygroups`,
    screen: FullLoader(
      () => import("../Screens/MasterData/PayGroups/PayGroups")
    ),
  },
  {
    path: `/paygroup/:id`,
    screen: FullLoader(
      () => import("../Screens/MasterData/PayGroups/PayGroupForm")
    ),
  },
  {
    path: `/add-contract-type`,
    screen: FullLoader(
      () => import("../Screens/MasterData/ContractTypes/ContractForm")
    ),
  },
  {
    path: `/contract-types`,
    screen: FullLoader(
      () => import("../Screens/MasterData/ContractTypes/ContractTypes")
    ),
  },
  {
    path: `/contract-type/:id`,
    screen: FullLoader(
      () => import("../Screens/MasterData/ContractTypes/ContractForm")
    ),
  },
  {
    path: `/add-region`,
    screen: FullLoader(
      () => import("../Screens/MasterData/Regions/RegionForm")
    ),
  },
  {
    path: `/regions`,
    screen: FullLoader(() => import("../Screens/MasterData/Regions/Regions")),
  },
  {
    path: `/region/:id`,
    screen: FullLoader(
      () => import("../Screens/MasterData/Regions/RegionForm")
    ),
  },
  {
    path: `/add-payroll-area`,
    screen: FullLoader(
      () => import("../Screens/MasterData/PayrollArea/PayrollAreaForm")
    ),
  },
  {
    path: `/payroll-areas`,
    screen: FullLoader(
      () => import("../Screens/MasterData/PayrollArea/PayrollArea")
    ),
  },
  {
    path: `/payroll-area/:id`,
    screen: FullLoader(
      () => import("../Screens/MasterData/PayrollArea/PayrollAreaForm")
    ),
  },
  {
    path: `/add-marital-status`,
    screen: FullLoader(
      () => import("../Screens/MasterData/MaritalStatus/MaritalStatusForm")
    ),
  },
  {
    path: `/marital-status`,
    screen: FullLoader(
      () => import("../Screens/MasterData/MaritalStatus/MaritalStatus")
    ),
  },
  {
    path: `/marital-status/:id`,
    screen: FullLoader(
      () => import("../Screens/MasterData/MaritalStatus/MaritalStatusForm")
    ),
  },
  {
    path: `/custom-templates`,
    screen: SideBarLoader(() => import("../Screens/Templates/TemplateNew")),
  },
  {
    path: `/custom-templates/:id`,
    screen: FullLoader(() => import("../Screens/Templates/editor1")),
  },

  {
    path: `/categories`,
    screen: SideBarLoader(
      () => import("../Screens/MasterData/Categories/Categories")
    ),
  },
  {
    path: `/add-category`,
    screen: FullLoader(
      () => import("../Screens/MasterData/Categories/CategoryForm")
    ),
  },
  {
    path: `/edit-category/:id`,
    screen: FullLoader(
      () => import("../Screens/MasterData/Categories/CategoryForm")
    ),
  },
  {
    path: `/add-grade`,
    screen: FullLoader(() => import("../Screens/MasterData/Grades/GradeForm")),
  },
  {
    path: `/grades`,
    screen: FullLoader(() => import("../Screens/MasterData/Grades/Grades")),
  },
  {
    path: `/grade/:id`,
    screen: FullLoader(() => import("../Screens/MasterData/Grades/GradeForm")),
  },
  {
    path: `additional-setup`,
    screen: FullLoader(
      () => import("../Screens/MasterData/Main/AdditionalSetup")
    ),
  },
  // {
  //   path: `master-upload`,
  //   screen: FullLoader(
  //     () => import("../Screens/MasterData/MasterUploads/UploadRoles")
  //   ),
  // },
  {
    path: `/add-power-of-attorney`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/PowerOfAttorneyType/PowerOfAttorneyTypeForm"
        )
    ),
  },
  {
    path: `/power-of-attorney-types`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/PowerOfAttorneyType/PowerOfAttorneyTypes"
        )
    ),
  },
  {
    path: `/edit-power-of-attorney/:id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/PowerOfAttorneyType/PowerOfAttorneyTypeForm"
        )
    ),
  },
  {
    path: `/add-custom-veteran`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/CustomVeteranStatus/CustomVeteranStatusForm"
        )
    ),
  },
  {
    path: `/custom-veteran`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/CustomVeteranStatus/CustomVeteranStatus"
        )
    ),
  },
  {
    path: `/edit-custom-veteran/:id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/CustomVeteranStatus/CustomVeteranStatusForm"
        )
    ),
  },
  {
    path: `/work-break-schedules`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/WorkBreakSchedule/WorkBreakSchedules"
        )
    ),
  },
  {
    path: `/add-work-break-schedule`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/WorkBreakSchedule/WorkBreakScheduleForm"
        )
    ),
  },
  {
    path: `/work-break-schedule/:id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/WorkBreakSchedule/WorkBreakScheduleForm"
        )
    ),
  },
  {
    path: `/work-time-event-type-groups`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/WorkTimeEventTypeGroup/WorkTimeEventTypeGroups"
        )
    ),
  },
  {
    path: `/add-work-time-event`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/WorkTimeEventTypeGroup/WorkTimeEventTypeGroupForm"
        )
    ),
  },
  {
    path: `/work-time-event/:id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/WorkTimeEventTypeGroup/WorkTimeEventTypeGroupForm"
        )
    ),
  },
  {
    path: `/daily-work-schedule-variants`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/DailyWorkScheduleVariant/DailyWorkScheduleVariants"
        )
    ),
  },
  {
    path: `/add-daily-work-schedule-variant`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/DailyWorkScheduleVariant/DailyWorkScheduleVariantForm"
        )
    ),
  },
  {
    path: `/edit-daily-work-schedule-variant/:id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/DailyWorkScheduleVariant/DailyWorkScheduleVariantForm"
        )
    ),
  },
  {
    path: `/contribution-plan`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/ContributionPlan/ContributionPlans"
        )
    ),
  },
  {
    path: `/edit-contribution-plan/:id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/ContributionPlan/ContributionPlanForm"
        )
    ),
  },
  {
    path: `/add-contribution-plan`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/ContributionPlan/ContributionPlanForm"
        )
    ),
  },
  {
    path: `/currency-key`,
    screen: FullLoader(
      () =>
        import("../Screens/MasterData/AdditionalSetup/CurrencyKey/CurrencyKeys")
    ),
  },
  {
    path: `/edit-currency-key/:id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/CurrencyKey/CurrencyKeyForm"
        )
    ),
  },
  {
    path: `/add-currency-key`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/CurrencyKey/CurrencyKeyForm"
        )
    ),
  },
  {
    path: `/promotion-on-transfer`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/PromotionOnTransfer/PromotionOnTransfers"
        )
    ),
  },
  {
    path: `/edit-promotion-on-transfer/:id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/PromotionOnTransfer/PromotionOnTransferForm"
        )
    ),
  },
  {
    path: `/add-promotion-on-transfer`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/PromotionOnTransfer/PromotionOnTransferForm"
        )
    ),
  },
  {
    path: `/add-allowance-authorization`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/AllowanceAuthorizations/AllowanceAuthorizationsForm"
        )
    ),
  },
  {
    path: `/allowance-authorizations`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/AllowanceAuthorizations/AllowanceAuthorizations"
        )
    ),
  },
  {
    path: `/edit-allowance-authorization/:id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/AllowanceAuthorizations/AllowanceAuthorizationsForm"
        )
    ),
  },
  {
    path: `/add-survey-participation-flag`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/SurveyParticipationFlag/SurveyParticipationFlagForm"
        )
    ),
  },
  {
    path: `/survey-participation-flags`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/SurveyParticipationFlag/SurveyParticipationFlag"
        )
    ),
  },
  {
    path: `/edit-survey-participation-flag/:id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/SurveyParticipationFlag/SurveyParticipationFlagForm"
        )
    ),
  },
  {
    path: `/add-badges`,
    screen: FullLoader(
      () => import("../Screens/MasterData/AdditionalSetup/Badge/BadgeForm")
    ),
  },
  {
    path: `/badges`,
    screen: FullLoader(
      () => import("../Screens/MasterData/AdditionalSetup/Badge/Badge")
    ),
  },
  {
    path: `/edit-badges/:id`,
    screen: FullLoader(
      () => import("../Screens/MasterData/AdditionalSetup/Badge/BadgeForm")
    ),
  },
  {
    path: `/add-overtime-compensation-types`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/OvertimeCompensationTypes/OvertimeCompensationTypesForm"
        )
    ),
  },
  {
    path: `/overtime-compensation-types`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/OvertimeCompensationTypes/OvertimeCompensationTypes"
        )
    ),
  },
  {
    path: `/edit-overtime-compensation-types/:id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/OvertimeCompensationTypes/OvertimeCompensationTypesForm"
        )
    ),
  },
  {
    path: `/add-work-schedule-rules`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/WorkScheduleRules/WorkScheduleRulesForm"
        )
    ),
  },
  {
    path: `/work-schedule-rules`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/WorkScheduleRules/WorkScheduleRules"
        )
    ),
  },
  {
    path: `/edit-work-schedule-rules/:id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/WorkScheduleRules/WorkScheduleRulesForm"
        )
    ),
  },
  {
    path: `/add-external-document-numbers`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/ExternalDocumentNumbers/ExternalDocumentNumbersForm"
        )
    ),
  },
  {
    path: `/external-document-numbers`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/ExternalDocumentNumbers/ExternalDocumentNumbers"
        )
    ),
  },
  {
    path: `/edit-external-document-numbers/:id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/ExternalDocumentNumbers/ExternalDocumentNumbersForm"
        )
    ),
  },
  {
    path: `/add-time-data-id-types`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/TimeDataIdTypes/TimeDataIdTypesForm"
        )
    ),
  },
  {
    path: `/time-data-id-types`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/TimeDataIdTypes/TimeDataIdTypes"
        )
    ),
  },
  {
    path: `/edit-time-data-id-types/:id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/TimeDataIdTypes/TimeDataIdTypesForm"
        )
    ),
  },
  {
    path: `/add-indicator-employee-has-access-to-insider-informations`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/IndicatorEmployeeHasAccessToInsiderInformation/IndicatorEmployeeHasAccessToInsiderInformationsForm"
        )
    ),
  },
  {
    path: `/indicator-employee-has-access-to-insider-informations`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/IndicatorEmployeeHasAccessToInsiderInformation/IndicatorEmployeeHasAccessToInsiderInformations"
        )
    ),
  },
  {
    path: `/edit-indicator-employee-has-access-to-insider-informations/:id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/IndicatorEmployeeHasAccessToInsiderInformation/IndicatorEmployeeHasAccessToInsiderInformationsForm"
        )
    ),
  },
  {
    path: `/life-events`,
    screen: FullLoader(
      () =>
        import("../Screens/MasterData/AdditionalSetup/LifeEvents/LifeEvents")
    ),
  },
  {
    path: `/add-life-event`,
    screen: FullLoader(
      () =>
        import("../Screens/MasterData/AdditionalSetup/LifeEvents/LifeEventForm")
    ),
  },
  {
    path: `/life-event/:id`,
    screen: FullLoader(
      () =>
        import("../Screens/MasterData/AdditionalSetup/LifeEvents/LifeEventForm")
    ),
  },
  {
    path: `/sickness-certificate-authorizations`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/SicknessCertificateAuthorizations/SicknessCertificateAuthorizations"
        )
    ),
  },
  {
    path: `/add-sickness-certificate-authorization`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/SicknessCertificateAuthorizations/SicknessCertificateAuthorizationForm"
        )
    ),
  },
  {
    path: `/sickness-certificate-authorization/:id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/SicknessCertificateAuthorizations/SicknessCertificateAuthorizationForm"
        )
    ),
  },
  {
    path: `/release-of-datas`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/ReleaseOfDatas/ReleaseOfDatas"
        )
    ),
  },
  {
    path: `/add-release-of-data`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/ReleaseOfDatas/ReleaseOfDataForm"
        )
    ),
  },
  {
    path: `/release-of-data/:id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/ReleaseOfDatas/ReleaseOfDataForm"
        )
    ),
  },
  {
    path: `/survey-answered`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/SurveyAnswered/SurveyAnswered"
        )
    ),
  },
  {
    path: `/add-survey-answered`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/SurveyAnswered/SurveyAnsweredForm"
        )
    ),
  },
  {
    path: `/survey-answered/:id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/SurveyAnswered/SurveyAnsweredForm"
        )
    ),
  },
  {
    path: `/employee-subgroup-grouping-for-work-schedules`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/EmployeeSubgroupGroupingForWorkSchedules/EmployeeSubgroupGroupingForWorkSchedules"
        )
    ),
  },
  {
    path: `/add-employee-subgroup-grouping-for-work-schedule`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/EmployeeSubgroupGroupingForWorkSchedules/EmployeeSubgroupGroupingForWorkScheduleForm"
        )
    ),
  },
  {
    path: `/employee-subgroup-grouping-for-work-schedule/:id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/EmployeeSubgroupGroupingForWorkSchedules/EmployeeSubgroupGroupingForWorkScheduleForm"
        )
    ),
  },
  {
    path: `/daily-work-schedules`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/DailyWorkSchedules/DailyWorkSchedules"
        )
    ),
  },
  {
    path: `/add-daily-work-schedule`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/DailyWorkSchedules/DailyWorkScheduleForm"
        )
    ),
  },
  {
    path: `/daily-work-schedule/:id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/DailyWorkSchedules/DailyWorkScheduleForm"
        )
    ),
  },
  {
    path: `/day-types`,
    screen: FullLoader(
      () => import("../Screens/MasterData/AdditionalSetup/DayTypes/DayTypes")
    ),
  },
  {
    path: `/add-day-type`,
    screen: FullLoader(
      () => import("../Screens/MasterData/AdditionalSetup/DayTypes/DayTypeForm")
    ),
  },
  {
    path: `/day-type/:id`,
    screen: FullLoader(
      () => import("../Screens/MasterData/AdditionalSetup/DayTypes/DayTypeForm")
    ),
  },
  {
    path: `/daily-work-schedule-classes`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/DailyWorkScheduleClasses/DailyWorkScheduleClasses"
        )
    ),
  },
  {
    path: `/add-daily-work-schedule-class`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/DailyWorkScheduleClasses/DailyWorkScheduleClassForm"
        )
    ),
  },
  {
    path: `/daily-work-schedule-class/:id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/DailyWorkScheduleClasses/DailyWorkScheduleClassForm"
        )
    ),
  },
  {
    path: `/transfer-accrued-entitlements`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/TransferAccruedEntitlements/TransferAccruedEntitlements"
        )
    ),
  },
  {
    path: `/add-transfer-accrued-entitlement`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/TransferAccruedEntitlements/TransferAccruedEntitlementForm"
        )
    ),
  },
  {
    path: `/transfer-accrued-entitlement/:id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/TransferAccruedEntitlements/TransferAccruedEntitlementForm"
        )
    ),
  },
  {
    path: `/time-measurement`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/TimeMeasurementUnits/TimeMeasurementUnits"
        )
    ),
  },
  {
    path: `/add-time-measurement`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/TimeMeasurementUnits/TimeMeasurementUnitForm"
        )
    ),
  },
  {
    path: `/edit-time-measurement/:id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/TimeMeasurementUnits/TimeMeasurementUnitForm"
        )
    ),
  },
  {
    path: `/grouping-connection-subsystem`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/GroupingForConnectionToSubsytems/GroupingForConnectionToSubsytems"
        )
    ),
  },
  {
    path: `/add-grouping-connection-subsystem`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/GroupingForConnectionToSubsytems/GroupingForConnectionToSubsytemsForm"
        )
    ),
  },
  {
    path: `/edit-grouping-connection-subsystem/:id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/GroupingForConnectionToSubsytems/GroupingForConnectionToSubsytemsForm"
        )
    ),
  },
  {
    path: `/grouping-attendance-absence-reason`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/GroupingOfAttendanceAbsenceReasons/GroupingOfAttendanceAbsenceReasons"
        )
    ),
  },
  {
    path: `/add-grouping-attendance-absence-reason`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/GroupingOfAttendanceAbsenceReasons/GroupingOfAttendanceAbsenceReasonsForm"
        )
    ),
  },
  {
    path: `/edit-grouping-attendance-absence-reason/:id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/GroupingOfAttendanceAbsenceReasons/GroupingOfAttendanceAbsenceReasonsForm"
        )
    ),
  },
  {
    path: `/end-of-break`,
    screen: FullLoader(
      () =>
        import("../Screens/MasterData/AdditionalSetup/EndOfBreaks/EndOfBreaks")
    ),
  },
  {
    path: `/add-end-of-break`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/EndOfBreaks/EndOfBreaksForm"
        )
    ),
  },
  {
    path: `/edit-end-of-break/:id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/EndOfBreaks/EndOfBreaksForm"
        )
    ),
  },
  {
    path: `/relationship-to-childs`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/RelationshipToChild/RelationshipToChild"
        )
    ),
  },
  {
    path: `/add-relationship-to-child`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/RelationshipToChild/RelationshipToChildForm"
        )
    ),
  },
  {
    path: `/edit-relationship-to-child/:id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/RelationshipToChild/RelationshipToChildForm"
        )
    ),
  },
  {
    path: `/add-international-hr-admin`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/InternationalHRAdministrator/InternationalHrAdminForm"
        )
    ),
  },
  {
    path: `/international-hr-admins`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/InternationalHRAdministrator/InternationalHrAdmins"
        )
    ),
  },
  {
    path: `/edit-international-hr-admin/:id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/InternationalHRAdministrator/InternationalHrAdminForm"
        )
    ),
  },
  {
    path: `/ntl-grandfathereds`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/NtlGrandfatheredType/NtlGrandfatherd"
        )
    ),
  },
  {
    path: `/edit-ntl-grandfathered/:id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/NtlGrandfatheredType/NtlGrandfatheredForm"
        )
    ),
  },
  {
    path: `/add-ntl-grandfathered`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/NtlGrandfatheredType/NtlGrandfatheredForm"
        )
    ),
  },
  {
    path: `/valuation-basis-for-different-payments`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/ValuationBasisForDifferentPaymentType/ValuationBasisForDifferentPayments"
        )
    ),
  },
  {
    path: `/edit-valuation-basis-for-different-payment/:id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/ValuationBasisForDifferentPaymentType/ValuationBasisForDifferentPaymentForm"
        )
    ),
  },
  {
    path: `/add-valuation-basis-for-different-payment`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/ValuationBasisForDifferentPaymentType/ValuationBasisForDifferentPaymentForm"
        )
    ),
  },
  {
    path: `/personal-NTL-bands`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/NTLPersonelNTLBand/NTLPersonalNTLBands"
        )
    ),
  },
  {
    path: `/add-personal-NTL-band`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/NTLPersonelNTLBand/NTLPersonalNTLBandForm"
        )
    ),
  },
  {
    path: `/edit-personal-NTL-band/:id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/NTLPersonelNTLBand/NTLPersonalNTLBandForm"
        )
    ),
  },
  {
    path: `/add-child-allowance`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/ChildAllowanceEntitlement/ChildAllowanceEntitlementForm"
        )
    ),
  },
  {
    path: `/child-allowances`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/ChildAllowanceEntitlement/ChildAllowanceEntitlements"
        )
    ),
  },
  {
    path: `/child-allowance/:id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/ChildAllowanceEntitlement/ChildAllowanceEntitlementForm"
        )
    ),
  },
  {
    path: `/add-name-object`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/NameOfObjectOnLoan/NameOfObjectOnLoanForm"
        )
    ),
  },
  {
    path: `/name-objects`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/NameOfObjectOnLoan/NameOfObjectOnLoans"
        )
    ),
  },
  {
    path: `/name-object/:id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/NameOfObjectOnLoan/NameOfObjectOnLoanForm"
        )
    ),
  },
  {
    path: `/add-do-not-account`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/DoNotAccount/DoNotAccountForm"
        )
    ),
  },
  {
    path: `/do-not-accounts`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/DoNotAccount/DoNotAccounts"
        )
    ),
  },
  {
    path: `/do-not-account/:id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/DoNotAccount/DoNotAccountForm"
        )
    ),
  },
  {
    path: `/add-day-indicator`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/PreviousDayIndicator/PreviousDayIndicatorForm"
        )
    ),
  },
  {
    path: `/day-indicators`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/PreviousDayIndicator/PreviousDayIndicators"
        )
    ),
  },
  {
    path: `/day-indicator/:id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/PreviousDayIndicator/PreviousDayIndicatorForm"
        )
    ),
  },
  {
    path: `/add-employee-use`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/EmployeeUsesESS/EmployeeUsesESS-DoNotPrintsForm"
        )
    ),
  },
  {
    path: `/employee-uses`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/EmployeeUsesESS/EmployeeUsesESS-DoNotPrints"
        )
    ),
  },
  {
    path: `/employee-use/:id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/EmployeeUsesESS/EmployeeUsesESS-DoNotPrintsForm"
        )
    ),
  },
  {
    path: `/definition-set-for-id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/DefinitionSetForlds/DefinitionSetForIds"
        )
    ),
  },
  {
    path: `/edit-definition-set-for-id/:id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/DefinitionSetForlds/DefinitionSetForIdsForm"
        )
    ),
  },
  {
    path: `/add-definition-set-for-id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/DefinitionSetForlds/DefinitionSetForIdsForm"
        )
    ),
  },
  {
    path: `/definition-subset-for-id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/DefinitionSubsetForIds/DefinitionSubsetForIds"
        )
    ),
  },
  {
    path: `/edit-definition-subset-for-id/:id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/DefinitionSubsetForIds/DefinitionSubsetForIdsForm"
        )
    ),
  },
  {
    path: `/add-definition-subset-for-id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/DefinitionSubsetForIds/DefinitionSubsetForIdsForm"
        )
    ),
  },
  {
    path: `/extra-pay-indicator`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/ExtraPayIndicators/ExtraPayIndicators"
        )
    ),
  },
  {
    path: `/edit-extra-pay-indicator/:id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/ExtraPayIndicators/ExtraPayIndicatorsForm"
        )
    ),
  },
  {
    path: `/add-extra-pay-indicator`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/ExtraPayIndicators/ExtraPayIndicatorsForm"
        )
    ),
  },
  {
    path: `/payment-methods`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/PaymentMethods/PaymentMethods"
        )
    ),
  },
  {
    path: `/edit-payment-method/:id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/PaymentMethods/PaymentMethodForm"
        )
    ),
  },
  {
    path: `/add-payment-method`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/PaymentMethods/PaymentMethodForm"
        )
    ),
  },
  {
    path: `/grouping-employee-expenses`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/GroupingForEmployeeExpenses/GroupingForEmployeeExpenses"
        )
    ),
  },
  {
    path: `/add-grouping-employee-expenses`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/GroupingForEmployeeExpenses/GroupingForEmployeeExpensesForm"
        )
    ),
  },
  {
    path: `/edit-grouping-employee-expenses/:id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/MasterData/AdditionalSetup/GroupingForEmployeeExpenses/GroupingForEmployeeExpensesForm"
        )
    ),
  },
  {
    path: `/subtype`,
    screen: FullLoader(
      () => import("../Screens/MasterData/AdditionalSetup/SubTypes/SubTypes")
    ),
  },
  {
    path: `/add-subtype`,
    screen: FullLoader(
      () => import("../Screens/MasterData/AdditionalSetup/SubTypes/SubTypeForm")
    ),
  },
  {
    path: `/edit-subtype/:id`,
    screen: FullLoader(
      () => import("../Screens/MasterData/AdditionalSetup/SubTypes/SubTypeForm")
    ),
  },
  {
    path: `/currencies`,
    screen: SideBarLoader(
      () => import("../Screens/MasterData/Currencies/Currencies")
    ),
  },
  {
    path: `/add-currency`,
    screen: FullLoader(
      () => import("../Screens/MasterData/Currencies/CurrencyForm")
    ),
  },
  {
    path: `/edit-currency/:id`,
    screen: FullLoader(
      () => import("../Screens/MasterData/Currencies/CurrencyForm")
    ),
  },
  {
    path: `/departments`,
    screen: SideBarLoader(
      () => import("../Screens/MasterData/Departments/Departments")
    ),
  },
  {
    path: `/add-department`,
    screen: FullLoader(
      () => import("../Screens/MasterData/Departments/DepartmentForm")
    ),
  },
  {
    path: `/edit-department/:id`,
    screen: FullLoader(
      () => import("../Screens/MasterData/Departments/DepartmentForm")
    ),
  },
  {
    path: `/divisions`,
    screen: SideBarLoader(
      () => import("../Screens/MasterData/Divisions/Divisions")
    ),
  },
  {
    path: `/add-division`,
    screen: FullLoader(
      () => import("../Screens/MasterData/Divisions/DivisionForm")
    ),
  },
  {
    path: `/edit-division/:id`,
    screen: FullLoader(
      () => import("../Screens/MasterData/Divisions/DivisionForm")
    ),
  },
  {
    path: `/exchange-rates`,
    screen: FullLoader(
      () => import("../Screens/Payroll/ExchangeRate/ExchangeRate")
    ),
  },
  {
    path: `/add-exchange-rate`,
    screen: FullLoader(
      () => import("../Screens/Payroll/ExchangeRate/ExchangeRateForm")
    ),
  },
  {
    path: `/edit-exchange-rate/:id`,
    screen: FullLoader(
      () => import("../Screens/Payroll/ExchangeRate/ExchangeRateForm")
    ),
  },
  {
    path: `translate`,
    screen: SideBarLoader(() => import("../Screens/Translator/Translator")),
  },
  {
    path: `add-translate`,
    screen: FullLoader(() => import("../Screens/Translator/TranslatorForm")),
  },
  {
    path: `edit-translate/:id`,
    screen: FullLoader(() => import("../Screens/Translator/TranslatorForm")),
  },
  {
    path: `termination`,
    screen: SideBarLoader(
      () => import("../Screens/HumanResources/Terminations/Termination")
    ),
  },
  {
    path: `add-termination`,
    screen: FullLoader(
      () => import("../Screens/HumanResources/Terminations/TerminateForm")
    ),
  },
  {
    path: `edit-termination/:id`,
    screen: FullLoader(
      () => import("../Screens/HumanResources/Terminations/TerminateForm")
    ),
  },

  {
    path: `employee-transfers`,
    screen: SideBarLoader(
      () =>
        import("../Screens/HumanResources/EmployeeTransfer/EmployeeTransfers")
    ),
  },
  {
    path: `add-employee-transfer`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/HumanResources/EmployeeTransfer/EmployeeTransferForm"
        )
    ),
  },
  {
    path: `edit-employee-transfer/:id`,
    screen: FullLoader(
      () =>
        import(
          "../Screens/HumanResources/EmployeeTransfer/EmployeeTransferForm"
        )
    ),
  },
  {
    path: `numbering-series`,
    screen: SideBarLoader(
      () => import("../Screens/MasterData/NumberingSeries/NumberingSeries")
    ),
  },

  {
    path: `asset-transfers`,
    screen: FullLoader(
      () => import("../Screens/HumanResources/AssetTransfers/AssetTransfers")
    ),
  },
  {
    path: `add-asset-transfer`,
    screen: FullLoader(
      () =>
        import("../Screens/HumanResources/AssetTransfers/AssetTransfersForm")
    ),
  },
  {
    path: `edit-asset-transfer/:id`,
    screen: FullLoader(
      () =>
        import("../Screens/HumanResources/AssetTransfers/AssetTransfersForm")
    ),
  },
  {
    path: `goals`,
    screen: SideBarLoader(
      () => import("../Screens/HumanResources/Performance/Goals")
    ),
  },
  {
    path: `add-goals`,
    screen: FullLoader(
      () => import("../Screens/HumanResources/Performance/GoalsForm")
    ),
  },
  {
    path: `edit-goals/:id`,
    screen: FullLoader(
      () => import("../Screens/HumanResources/Performance/GoalsForm")
    ),
  },
  {
    path: `employees-benefits`,
    screen: FullLoader(
      () => import("../Screens/MasterData/BenefitsDetails/Benefits")
    ),
  },
  {
    path: '/employees-payroll',
    screen: FullLoader(() => import('../Screens/MasterData/PayrollDetails/Payroll'))
  },
  {
    path: '/employees-payroll/:id',
    screen: FullLoader(() => import('../Screens/MasterData/PayrollDetails/PayrollDetails'))
  },
  {
    path: `employees-benefits/:id`,
    screen: FullLoader(
      () => import("../Screens/MasterData/BenefitsDetails/BenefitsDetails")
    ),
  },
  {
    path: `employees-benefits/:id`,
    screen: FullLoader(
      () => import("../Screens/MasterData/BenefitsDetails/BenefitsDetails")
    ),
    access: "employees-benefits",
  },
  {
    path: `employees`,
    screen: SideBarLoader(
      () => import("../Screens/MasterData/Employees/Employees")
    ),
  },
  {
    path: `add-employee`,
    screen: FullLoader(
      () => import("../Screens/HumanResources/EmployeeDetails/EmployeeDetails")
    ),
  },
  {
    path: `edit-employee/:id`,
    screen: FullLoader(
      () => import("../Screens/HumanResources/EmployeeDetails/EmployeeDetails")
    ),
    access: "edit-employee",
  },
  {
    path: `/employees-compensation`,
    screen: FullLoader(
      () => import("../Screens/MasterData/CompensationDetails/Compensation")
    ),
  },
  {
    path: `/employees-compensation/:id`,
    screen: FullLoader(
      () =>
        import("../Screens/MasterData/CompensationDetails/CompensationDetails")
    ),
  },
  {
    path: `employees-t&a`,
    screen: FullLoader(() => import("../Screens/MasterData/T&ADetails/T&A")),
  },
  {
    path: `/employees-t&a/:id`,
    screen: FullLoader(
      () => import("../Screens/MasterData/T&ADetails/T&ADetails")
    ),
  },
  {
    path: `employees-hcm`,
    screen: FullLoader(() => import("../Screens/MasterData/HCMDetails/HCM")),
  },
  {
    path: `/employees-hcm/:id`,
    screen: FullLoader(
      () => import("../Screens/MasterData/HCMDetails/HCMDetails")
    ),
  },
  {
    path: `/field-repos/employees`,
    screen: FullLoader(
      () => import("../Screens/HumanResources/FieldRepos/EmployeeList")
    ),
  },
  {
    path: `/field-repos/employees/:id`,
    screen: FullLoader(() => import("../Screens/HumanResources/FieldRepos")),
  },
  {
    path: `/hr-configurations`,
    screen: FullLoader(() => import('../Screens/HumanResources/HrConfigurations/HrConfigurations'))
  },
  {
    path: `/add-company`,
    screen: FullLoader(() => import('../Screens/MasterData/Companies/CompanyForm')),
    access: "",
  },
  {
    path: `/edit-company/:id`,
    screen: FullLoader(() => import('../Screens/MasterData/Companies/CompanyForm')),
    access: "",
  },
  {
    path: `/employees-roles`,
    screen: FullLoader(() => import('../Screens/MasterData/Employee_Roles/Roles'))
  },
  {
    path: `/employees-roles-edit/:id`,
    screen: FullLoader(() => import('../Screens/MasterData/Employee_Roles/RolesEditForm'))
  },
  {
    path: `/employee-shift`,
    screen: FullLoader(() => import('../Screens/HumanResources/EmployeeShift/Shift'))
  },
  {
    path: `/edit-employee-shift/:id`,
    screen: FullLoader(() => import('../Screens/HumanResources/EmployeeShift/ShiftForm'))
  },
  {
    path: `/add-employee-shift`,
    screen: FullLoader(() => import('../Screens/HumanResources/EmployeeShift/ShiftForm'))
  },
  { path: `/projects`, screen: FullLoader(() => import('../Screens/ProjectData/Projects/Projects')) },
  {
    path: `/add-project`,
    screen: FullLoader(() => import('../Screens/ProjectData/Projects/ProjectForm'))
  },
  {
    path: `/edit-project/:id`,
    screen: FullLoader(() => import('../Screens/ProjectData/Projects/ProjectForm'))
  },
  {
    path: `/project-employee-rates`,
    screen: FullLoader(() => import('../Screens/ProjectData/ProjectAndEmployeeRates/ProjectAndEmployeeRates'))
  },
  {
    path: `/add-project-employee-rate`,
    screen: FullLoader(() =>
      import('../Screens/ProjectData/ProjectAndEmployeeRates/ProjectAndEmployeeRateForm')
    )
  },
  {
    path: `/edit-project-employee-rate/:id`,
    screen: FullLoader(() =>
      import('../Screens/ProjectData/ProjectAndEmployeeRates/ProjectAndEmployeeRateForm')
    )
  },
];

const InnerRoutes = (props: any) => {
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const userState = useSelector((state: RootState) => state.userState);
  const location = useLocation();
  const url = location.pathname;
  useEffect(() => {
    const releaseVersion = '1.0.23';
    
    if (localStorage.getItem('release_version') !== releaseVersion) {
      Modal.info({
        width: '60%',
        title: 'Dear Customer',
        content: <ReleaseInfo />,
        onOk: () => {
          localStorage.setItem('release_version', releaseVersion);
        },
      });
  
      // Update localStorage immediately after showing the modal
      localStorage.setItem('release_version', releaseVersion);
    }
  }, []);

  const redirectToCompany = () => {
    navigate("/app/manage-company");
  };

  if (loader) {
    return (
      <div>
        <LoaderBox style={{ height: "100vh" }} loader="Loading.." />
      </div>
    );
  }

  console.log('Routes rendering:', {
    userState,
    currentUrl: url,
    asyncRoutes: getAsyncRoutes(url)
  });

  return (
    <Layout>
      <Routes>
        {getRoutes(url).map(({ path, component: Component }) => (
          <Route
            key={path}
            path={path}
            element={<RouteWrapper screen={Component} />}
          />
        ))}

        {getAsyncRoutes(url).map(({ path, access, screen, ...restProps }) => {
          console.log('Rendering async route:', {
            path,
            access,
            hasScreen: Boolean(screen)
          });
          
          return (
            <Route
              key={path}
              path={path}
              element={
                <AsyncRoute
                  routeAccess={access || []}
                  screen={screen}
                  {...restProps}
                />
              }
            />
          );
        })}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Layout>
  );
};

export default InnerRoutes;

// const InnerRoutes = (props: any) => {
//   const [loader, setLoader] = useState(false);
//   const dispatch = useDispatch<AppDispatch>();
//   const navigate = useNavigate();
//   const userState = useSelector((state: RootState) => state.userState); // Adjust based on your new slice structure
//   const location = useLocation(); // Access location for URL
//   const url = location.pathname;
  
//   useEffect(() => {
//     if (localStorage.getItem('release_version') !== '1.0.23') {
//       Modal.info({
//         width: '60%',
//         title: 'Dear Customer',
//         content: <ReleaseInfo />,
//         onOk: () => localStorage.setItem('release_version', '1.0.23')
//       })
//     }
//   }, []);

//   // useEffect(() => {
//   //   return () => {
//   //     dispatch(setTimerCount(false)); // Action from the new slice
//   //   };
//   // }, []);

//   const checkTimer = (user: any) => {
//     if (user?.timeEntryId) {
//     }
//   };

//   const redirectToCompany = () => {
//     navigate("/app/manage-company");
//   };

//   if (loader) {
//     return (
//       <div>
//         <LoaderBox style={{ height: "100vh" }} loader="Loading.." />
//       </div>
//     );
//   }

//   return (
//     <Layout>
//       <Routes>
//         {getRoutes(url).map(({ path, component }) => (
//           <Route
//             key={path}
//             path={path}
//             element={<RouteWrapper screen={component} />}
//           />
//         ))}

//         {getAsyncRoutes(url).map(({ path, access, screen, ...restProps }) => (
//           <Route
//             key={path}
//             path={path}
//             element={
//               <AsyncRoute
//                 routeAccess={access}
//                 screen={screen}
//                 {...props}
//                 {...restProps}
//               />
//             }
//           />
//         ))}
//         <Route path="*" element={<NotFound />} />
//       </Routes>
//     </Layout>
//   );
// };

// export default InnerRoutes;
