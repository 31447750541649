// branding
import accqrateIcon from "../assets/images/brand/accqrate-hcm-icon.svg";
import accqrateWhiteLogo from "../assets/images/brand/accqrate-hcm-white.svg";
import accqrateDarkLogo from "../assets/images/brand/accqrate-hcm.svg";
import modernMinimalistOffice from "../assets/images/modern-and-minimalist-office.jpg";

// dashboard
import accqrateWorkPlace from "../assets/images/accqrate-hcm-workplace.jpg";

// page not found
import pageNotFound from "../assets/images/pagenot-found.svg";

// menu icons
import settings from "../assets/images/menu/setups.svg";
import documents from "../assets/images/menu/documents.svg";
import masterSettings from "../assets/images/menu/master-settings.svg";
import peopleOrganization from "../assets/images/menu/people-and-organization.svg";
import projects from "../assets/images/menu/projects.svg";
import recruitment from "../assets/images/menu/recruitment.svg";
import time from "../assets/images/menu/time.svg";
import repository from "../assets/images/menu/repository.svg";

// icons
import Companies from "../assets/images/icons/company.svg";
import Roles from "../assets/images/icons/roles.svg";
import Users from "../assets/images/icons/users.svg";
import Customers from "../assets/images/icons/customer.svg";
import Currencies from "../assets/images/icons/currencies.svg";
import ExchangeRate from "../assets/images/icons/exchange.svg";
import Categories from "../assets/images/icons/categories.svg";
import Options from "../assets/images/icons/options.svg";
import CustomTemplate from "../assets/images/icons/templates.svg";
import NumberingSeries from "../assets/images/icons/numbering-series.svg";
import CompanyConfigurations from "../assets/images/icons/company.svg";
import CostCenter from "../assets/images/icons/cost-center.svg";
import Location from "../assets/images/icons/location.svg";
import EmployeeGroup from "../assets/images/icons/employee-group.svg";
import EmployeeSubGroup from "../assets/images/icons/employee-sub-group.svg";
import Nationality from "../assets/images/icons/nationality.svg";
import MaritalStatus from "../assets/images/icons/marital-status.svg";
import Gender from "../assets/images/icons/gender.svg";
import WageType from "../assets/images/icons/wage.svg";
import Grades from "../assets/images/icons/grades.svg";
import OrganizationUnit from "../assets/images/icons/organizational-units.svg";
import Position from "../assets/images/icons/position.svg";
import JobLevel from "../assets/images/icons/job-level.svg";
import OperationalLevel from "../assets/images/icons/operation-level.svg";
import MilitaryStatus from "../assets/images/icons/military.svg";
import PayrollArea from "../assets/images/icons/payroll.svg";
import Title from "../assets/images/icons/title.svg";
import Citizenship from "../assets/images/icons/citizenship.svg";
import Region from "../assets/images/icons/region.svg";
import ContractTypes from "../assets/images/icons/contract-types.svg";
import PayGroup from "../assets/images/icons/pay-group.svg";
import JobBand from "../assets/images/icons/job-level.svg";
import Disability from "../assets/images/icons/disability.svg";
import Religion from "../assets/images/icons/religion.svg";
import GovernmentID from "../assets/images/icons/government.svg";

// shapes
import arrowLeft from "../assets/images/shape/arrow.svg";

const AssetPath = {
  brand: {
    accqrateIcon,
    accqrateDarkLogo,
    accqrateWhiteLogo,
  },
  shape: {
    arrowLeft,
  },
  image: {
    modernMinimalistOffice,
    // dashboard
    accqrateWorkPlace,
    pageNotFound,
  },
  menu: {
    settings,
    documents,
    masterSettings,
    peopleOrganization,
    projects,
    recruitment,
    time,
    repository,
  },
  icons: {
    Companies,
    Roles,
    Users,
    Customers,
    Currencies,
    ExchangeRate,
    Categories,
    Options,
    CustomTemplate,
    NumberingSeries,
    CompanyConfigurations,
    CostCenter,
    Location,
    EmployeeGroup,
    EmployeeSubGroup,
    Nationality,
    MaritalStatus,
    Gender,
    WageType,
    Grades,
    OrganizationUnit,
    Position,
    JobLevel,
    OperationalLevel,
    MilitaryStatus,
    PayrollArea,
    Title,
    Citizenship,
    Region,
    ContractTypes,
    PayGroup,
    JobBand,
    Disability,
    Religion,
    GovernmentID,
  },
};

export default AssetPath;
