import { Link } from "react-router-dom";
import AssetPath from "../AssetPath/AssetPath";
import { Col, Row } from "antd";

export function NotFound() {
  return (
    <section className="spacing d-flex flex-column align-items-center justify-content-center">
      <Row justify="center" align="middle">
        <Col
          xs={{ span: 24 }}
          sm={{ span: 12 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}
          className="text-left"
        >
          <h1 className="h3">
            Oops! The page you were looking for doesn't exist.
          </h1>
          <p className="text-gray">
            You may have miss typed the address or the page may have moved
          </p>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 12 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}
        >
          <img src={AssetPath.image.pageNotFound} alt="Page Not Found" />
        </Col>
      </Row>
    </section>
  );
}
