// import React from 'react';
// import { Helmet } from 'react-helmet';
// import { useSelector } from 'react-redux';
// import { validateAccess } from '../util/util';
// import { NotFound } from "../Screens/notFound";
// import { RootState } from "../store/store";

// interface AsyncRouteProps {
//     routeAccess?: string | string[];  // Made optional
//     screen: React.ComponentType<any>;
//     [key: string]: any;
// }

// interface UserState {
//     userInfo: any;
//     companyInfo: any;
//     access: string[];
// }

// const AsyncRoute: React.FC<AsyncRouteProps> = ({ routeAccess = '', screen: Component, ...props }) => {  // Added default value
//     const defaultMetaObject = {
//         title: 'Accqrate Human Capital management solution',
//         description: 'Accqrate Human Capital management solution',
//         keyword: 'Accqrate Human Capital management solution',
//     };

//     // Access the necessary state using Redux hooks
//     const userState = useSelector((state: RootState) => state.userState as UserState);
//     const { access = [] } = userState;

//     const hasAccess = React.useMemo(() => {
//         console.log('Checking access:', {
//             access,
//             routeAccess,
//             accessExists: Boolean(access),
//             routeAccessExists: Boolean(routeAccess)
//         });

//         return validateAccess(access, routeAccess);
//     }, [access, routeAccess]);

//     // Early return for loading state if needed
//     if (access === undefined) {
//         console.log('Access is undefined, showing loading state');
//         return <div>Loading...</div>;
//     }

//     if (hasAccess) {
//         return (
//             <div style={{ height: '100%' }}>
//                 <Helmet>
//                     <title>{defaultMetaObject.title}</title>
//                     <meta name="description" content={defaultMetaObject.description} />
//                     <meta name="keyword" content={defaultMetaObject.keyword} />
//                 </Helmet>
//                 <Component {...props} />
//             </div>
//         );
//     }

//     console.log('Access denied, showing NotFound component');
//     return <NotFound />;
// };

// export default AsyncRoute;


import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { validateAccess } from '../util/util';
import { NotFound } from "../Screens/notFound";
import { RootState } from "../store/store";

interface AsyncRouteProps {
    routeAccess: string[] | string;
    screen: React.ComponentType<any>;
    [key: string]: any;
}

const AsyncRoute: React.FC<AsyncRouteProps> = ({ routeAccess = [], screen: Component, ...props }) => {
    const defaultMetaObject = {
        title: 'Accqrate Human Capital management solution',
        description: 'Accqrate Human Capital management solution',
        keyword: 'Accqrate Human Capital management solution',
    };

    // Access the necessary state using Redux hooks
    const userState = useSelector((state: RootState) => state.userState);
    const { access = [] } = userState;

    const hasAccess = React.useMemo(() => {
        console.log('AsyncRoute checking access:', {
            userAccess: access,
            routeAccess,
            userState
        });

        // If routeAccess is empty array or empty string, allow access
        if (Array.isArray(routeAccess) && routeAccess.length === 0) {
            return true;
        }
        if (typeof routeAccess === 'string' && routeAccess === '') {
            return true;
        }

        return validateAccess(access, routeAccess);
    }, [access, routeAccess]);

    console.log('AsyncRoute render decision:', {
        hasAccess,
        Component: Component?.name,
        routeAccess
    });

    if (hasAccess) {
        return (
            <div style={{ height: '100%' }}>
                <Helmet>
                    <title>{defaultMetaObject.title}</title>
                    <meta name="description" content={defaultMetaObject.description} />
                    <meta name="keyword" content={defaultMetaObject.keyword} />
                </Helmet>
                <Component {...props} />
            </div>
        );
    }

    return <NotFound />;
};

export default AsyncRoute;