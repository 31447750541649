
import { memo, lazy, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { FullLoader } from '../Components/LoaderBox/Loader';
import RouteWrapper from "./RouteWrapper";
import {NotFound} from "../Screens/notFound";




// Lazy-loaded components


const metaDetails = {
    aboutUs: {
        title: 'Digitize your business with cloud-based ERP along with online e-invoicing (Fatoorah QR code) solution in Saudi Arabia',
        description:
            'Cloud-based ERP and online e-invoicing (Fatoorah QR code) solution provider to digitize your business in 2 hours with low price',
        keyword: 'Digital Business, Cloud-based ERP, e-invoice (Fatoorah qr code) in Saudi, fatoorah qr code',
    },
    pricing: {
        title: 'Low Budget online e-invoicing (Fatoorah QR code) solution with ZATCA procedure',
        description:
            'Safe, online, low budget e-invoicing (Fatoorah QR code) solution provider to improve your business in Saudi Arabia (KSA)',
        keyword: 'Low budget e-invoice solution, fatoorah Saudi Arabia',
    },
    login:{
        title: 'Accqrate Human Capital management solution',
        description:
            'Accqrate Human Capital management solution',
        keyword: 'Accqrate Human Capital management solution',
    },
};

const routes = [
    { path: '/', screen: FullLoader(() => import('../Screens/Login/Login')), headerFooter: false },
    { path: '/register', screen: FullLoader(() => import('../Screens/Register/Register')), headerFooter: false },
    { path: '/login', screen: FullLoader(() => import('../Screens/Login/Login')), headerFooter: false, metaObj: metaDetails.login },

];

function AppRoutes() {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Routes>
                {routes.map(({ path, screen: ScreenComponent,  headerFooter = true, metaObj }) => (
                    <Route
                        key={path}
                        path={path}
                        element={<RouteWrapper headerFooter={headerFooter} metaObj={metaObj} screen={ScreenComponent} />}
                    />
                ))}
                <Route path="/app" element={<Navigate to="/login" replace />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Suspense>
    );
}

export default memo(AppRoutes);
