import { memo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'



interface TranslateProps {
  children: React.ReactNode;
}

// Function to translate text based on current language and translations
const t = (text: string, translations: Record<string, any>, language: string): string => {
  return translations[text]?.[language] ?? text
}
const Translate: React.FC<TranslateProps> = ({ children }) => {
  const { translations, language } = useSelector((state: RootState) => state.languageState);

  const translate = (text: string) => {
    // @ts-ignore
    return translations[text] && translations[text][language] ? translations[text][language] : text;
  };

  return <>{translate(children as string)}</>;
};

export default memo(Translate);

export { t }
