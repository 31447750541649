import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import AppConfig from '../config';
import { message } from 'antd';

// Create an instance of axios
const apiClient = axios.create({
    baseURL: AppConfig.API_URL,
});

// Request interceptor to attach the authorization token

apiClient.interceptors.request.use(
    (config: any) => {
        const token = localStorage.getItem('ACCOUNTING_TOKEN');

        if (token) {
            // Attach the Authorization header if token is present
            config.headers = {
                ...config.headers,
                Authorization: `Bearer ${token}`,
            };
        }

        return config;
    },
    (error: AxiosError) => {
        return Promise.reject(error);
    }
);

// Response interceptor to handle specific response cases
apiClient.interceptors.response.use(
    (response: AxiosResponse) => {
        return response;
    },
    (error: AxiosError) => {
        const response = error.response;

        if (response && response.status === 401) {
            // Handle 401 Unauthorized error: Logout and redirect to login
            localStorage.removeItem('ACCOUNTING_USER');
            window.location.href = '/login';
        }

        return Promise.reject(error);
    }
);

// Function to make API requests
const apiRequest = async (url: string, method: 'GET' | 'POST' | 'PUT' | 'DELETE', data?: any,headers?:any) => {
    try {

        const response = await apiClient.request({
            url,
            method,
            data: method !== 'GET' ? data : undefined, // Only send `data` for non-GET requests
            params: method === 'GET' ? data?.params??undefined : undefined,
            headers
        });
        return response.data;
    } catch (error) {
        // Handle errors
        if (error instanceof AxiosError) {
            const errorMessage = error.response?.data?.message || error.message;
            message.error(errorMessage);
        }
        throw error;
    }
};

export default apiRequest;
