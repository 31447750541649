import { Layout } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { JSX } from "react/jsx-runtime";
import { HeaderLoader } from "../Components/LoaderBox/Loader";
import "./Header.scss";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import Sidebar from "./Sidebar";

const HeaderNav = HeaderLoader(() => import("./Header"));

const { Header, Content } = Layout;

export default function Layouts(props: any) {
  const { t } = useTranslation(); // Use the useTranslation hook
  const navigate = useNavigate(); // Use useNavigate instead of useHistory
  const userInfo = useSelector((state: RootState) => state.userState) || {};

  useEffect(() => {
    const currentUser = localStorage.getItem("ACCOUNTING_USER");

    if (!currentUser) {
      navigate("/");
    }
  }, [navigate]); // Added navigate to dependencies array

  useEffect(() => {
    const handleScroll = () => {
      const appView = document.getElementById("app-view");
      if (appView) {
        appView.scrollIntoView();
      }
    };

    // Set up the scroll listener
    window.addEventListener("popstate", handleScroll);
    return () => {
      // Clean up the listener
      window.removeEventListener("popstate", handleScroll);
    };
  }, []);

  return (
    <Layout>
      <div id="app-view" />
      <Layout className="site-layout">
        <Header
          className="fixed-header"
          id="fixed-header"
          style={{ padding: 0 }}
        >
          <HeaderNav {...props} t={t} />{" "}
          {/* Pass t function to HeaderNav if needed */}
        </Header>
        <Layout>
          <Sidebar />

          <Content className="default-spacing content-container">
            {props.children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}
