import { DownOutlined, GlobalOutlined } from '@ant-design/icons'
import { Popover, Space } from 'antd'
import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import apiClient from '../../util/apiClient'
import { RootState } from '../../store/store'
import apiRequest from "../../util/apiClient";
import {setLanguage, setTranslations} from "../../store/slice/languageSlice";

const languages = [
  { label: 'English', value: 'en' },
  { label: 'عربي', value: 'ar' }
]

function ChangeLanguage() {
  const language = useSelector((state: RootState) => state.languageState.language)
  const dispatch = useDispatch()
  const [currentLanguage, setCurrentLanguage] = useState(language)

  const onChangeLang = async (lang: string) => {
    try {
      const data:any = await apiRequest('translations/list', 'GET', { language: lang });
      if (data?.result) {
        document.getElementById('root')?.style.setProperty('direction', lang === 'ar' ? 'rtl' : '');
        dispatch(setTranslations(data.result))
        dispatch(setLanguage(lang))
        setCurrentLanguage(lang)
      }

    } catch (error) {
      console.error('Error fetching translations:', error)
    }
  }

  const tableContent = () => (
      <div className="action-buttons">
        <ul>
          {languages.map((v, i) => (
              <li key={i} onClick={() => onChangeLang(v.value)}>
                <div>{v.label}</div>
              </li>
          ))}
        </ul>
      </div>
  )

  const selectedLanguage = useMemo(() => languages.find((v) => v.value === currentLanguage), [currentLanguage])

  return (
      <div className="cursor-pointer">
        <Popover placement="bottom" content={tableContent} trigger="click">
          <div className="language-translate">
            <Space size={5}>
              <GlobalOutlined />
              <div style={{ fontSize: 16 }}>{selectedLanguage?.label}</div>
              <DownOutlined />
            </Space>
          </div>
        </Popover>
      </div>
  )
}

export default ChangeLanguage
